import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { useAPI } from "../hooks/use-api"
import { useContentfulClient } from "../hooks/use-contentful-client"

const isApproved = {
  "fields.approved": true,
  "fields.organization.sys.contentType.sys.id": "organization",
  "fields.organization.fields.approved": true,
}

export const useEventService = () => {
  const {
    allContentfulExperience: { nodes: experiences },
    site: {
      siteMetadata: { apiUrl },
    },
  } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          apiUrl
        }
      }
      allContentfulExperience(sort: { fields: experienceName, order: ASC }) {
        nodes {
          experienceIcon {
            file {
              url
            }
          }
          contentful_id
          experienceName
        }
      }
    }
  `)
  const [response, setResponse] = React.useState({
    data: null,
    loading: false,
    error: null,
  })
  const api = useAPI(setResponse)
  const { get } = useAPI(setResponse)
  const { getClient } = useContentfulClient()
  const getFields = React.useCallback(async () => {
    await get(`${apiUrl}/events/fields`)
  }, [get, apiUrl])
  const findEvents = React.useCallback(
    async query => {
      setResponse(response => ({ ...response, loading: true }))
      try {
        const entries = await getClient().getEntries({
          include: 2,
          content_type: "event",
          ...isApproved,
          ...query,
          limit: 1000,
        })
        setResponse({
          data: entries.items.map(item => item),
          loading: false,
        })
      } catch (err) {
        setResponse(response => ({ ...response, loading: false, error: err }))
      }
    },
    [setResponse, getClient]
  )

  const findEventsGroupedByExperience = React.useCallback(
    async query => {
      setResponse(response => ({ ...response, loading: true }))
      try {
        const eventsByExperiencePromises = experiences.map(experience =>
          getClient().getEntries({
            include: 2,
            content_type: "event",
            "fields.experience.sys.id": experience.contentful_id,
            ...isApproved,
            ...query,
            limit: 1000,
          })
        )
        const eventsByExperience = await Promise.all(eventsByExperiencePromises)
        const data = eventsByExperience.map((events, index) => ({
          events: events.items,
          experience: experiences[index],
        }))
        setResponse({
          data,
          loading: false,
        })
      } catch (err) {
        setResponse(response => ({ ...response, loading: false, error: err }))
      }
    },
    [setResponse, getClient, experiences]
  )
  const findEventsByCollection = React.useCallback(
    async (collection, organization) => {
      setResponse(response => ({ ...response, loading: true }))
      if (!collection || !organization) {
        setResponse({
          data: [],
          loading: false,
        })
      } else
        try {
          const entries = await getClient().getEntries({
            content_type: "event",
            "fields.collection.sys.contentType.sys.id": "collection",
            "fields.collection.fields.name": collection.name,
            "fields.organization.sys.contentType.sys.id": "organization",
            "fields.organization.fields.name": organization.name,
            ...isApproved,
          })
          setResponse({
            data: entries.items.map(item => item.fields),
            loading: false,
          })
        } catch (err) {
          setResponse(response => ({ ...response, loading: false, error: err }))
        }
    },
    [setResponse, getClient]
  )
  const post = React.useCallback(
    ({ data, files, callback }) => {
      api.post({
        data,
        files,
        callback,
        url: `${apiUrl}/events`,
      })
    },
    [api, apiUrl]
  )
  return {
    getFields,
    findEvents,
    findEventsByCollection,
    findEventsGroupedByExperience,
    post,
    response,
  }
}
